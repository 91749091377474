.book-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.book-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 800px;
}

.book-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9;
  flex-direction: row;
}

.book-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.book-image {
  width: 100px;
  height: auto;
  margin-right: 20px;
  border-radius: 5px;
}

.book-info {
  flex: 1;
}

.book-rank-points {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.book-rank {
  font-size: 2em;
  font-weight: bold;
  margin-right: 10px;
}

.book-points {
  font-size: 1.2em;
  color: #888;
}

.book-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
}

.book-author,
.book-date {
  font-size: 1em;
  color: #555;
}

.qiita-links {
  margin-top: 10px;
}

.qiita-links h3 {
  font-size: 1.0em;
  margin-bottom: 10px;
  color: #333;
}

.book-url {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.book-url .dot {
  margin-right: 5px;
  color: #888;
}

.show-more {
  background: none;
  border: none;
  color: #1e90ff;
  cursor: pointer;
  padding: 0;
  font-size: 0.9em;
}

.buy-buttons {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.buy-button,
.buy-button-rakuten {
  width: 120px; /* 追加: ボタンの幅を統一 */
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease; /* 追加: ホバー時の背景色変更のトランジション */
}

.buy-button {
  background-color: #ff9900;
}

.buy-button:hover {
  background-color: #e68a00; /* ホバー時の色を追加 */
}

.buy-button-rakuten {
  background-color: #bf0000;
}

.buy-button-rakuten:hover {
  background-color: #a30000; /* ホバー時の色を追加 */
}

@media (max-width: 768px) {
  .book-list-container {
      padding: 10px;
  }
  
  .book-item {
      flex-direction: column;
      align-items: center;
      margin: 50px 0;
      padding: 15px;
  }
  
  .book-content {
      flex-direction: column;
      align-items: center;
  }
  
  .book-image {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
      max-width: 150px;
  }
  
  .book-rank-points {
      justify-content: center;
  }
  
  .book-info {
      text-align: center;
  }
  
  .book-url {
      text-align: left; /* テキストの左揃え */
  }
  
  .buy-button,
  .buy-button-rakuten {
      width: 100%; /* 幅を100%に設定 */
      max-width: 200px; /* 最大幅を設定 */
  }
}
