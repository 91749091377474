.header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #2e3232;
}

.header {
    display: flex;
    width: 80%; /* 適宜調整 */
    max-width: 1200px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}

.headerLeft {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.headerLeft__object > h1 {
    margin: 0 10px 5px 3px;
    cursor: pointer;
}

h1.logo {
    color: white;
    background: #55c500;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    border: none;
    white-space: nowrap;
}

.headerRight {
    display: flex;
    align-items: start;
    flex-shrink: 0;
}

.headerRight__object {
    display: flex;
    align-items: start;
    margin-right: 20px;
}

.headerRight__object > p {
    color: white;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
}

.headerRight__object > p:hover {
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    h1.logo{
        font-size: 22px;
    }
    .header {
        flex-direction: column;
        height: auto;
        padding: 10px;
        justify-content: center;
    }

    .headerLeft {
        justify-content: center;
        margin-bottom: 10px;
    }

    .headerRight {
        justify-content: space-between;
        margin-bottom: 10px;
        width: 60%;
    }

    .headerRight__object {
        margin-right: 0;
    }
}


@media screen and (max-width: 500px) {
    .headerRight {
        width: 80%;
    }
}