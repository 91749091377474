/* PointModal.css */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .modal.show {
    display: block;
    opacity: 1;
  }
  
  .modal.hide {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 10px;
    animation: slide-down 0.5s ease forwards;
  }
  
  .modal.hide .modal-content {
    animation: slide-up 0.5s ease forwards;
  }

  .font-weight-bold-modal {
    font-weight: bold;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
  
  .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
  }
  