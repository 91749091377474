.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2e3232; /* Headerと同じ背景色 */
    width: 100%; /* 幅を100%に設定 */
    height: 100px; /* 高さを少し高めに設定 */
    padding: 10px;
    box-sizing: border-box; /* パディングを含むように設定 */
}

.footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footerLogo {
    color: white;
    background: #55c500; /* ロゴ背景色 */
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    border:none;
}

.footerText {
    color: white;
    font-size: 14px;
    margin: 5px 0 0 0;
}
