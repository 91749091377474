/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: scroll; /* ページ全体のスクロールを有効にする */
}

.ad-container {
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  padding: 10px;
}

.ad-message {
  margin: 0;
  font-size: 1em;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.header-container h1 {
  display: inline;
}

.info-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.info-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.info-text {
  font-size: 1em;
  margin-left: 5px;
  color: #333;
  cursor: pointer;
}

.info-text:hover {
  text-decoration: underline;
}

.tags-container {
  text-align: center;
  margin-bottom: 20px;
}

.tags-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.MuiButtonBase-root.tag-item {
  font-size: 1.2em;
  color: white;
  background-color: #55c500;
  border-radius: 16px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  color: #000;
}

.MuiButtonBase-root.tag-item:hover {
  background-color: #66e600;
}

.MuiButtonBase-root.tag-item.show-all {
  background-color: #1d9aff;
  color: white;
}

.tag-item.show-all:hover {
  background-color: #73c0ff;
}

h1 {
  margin-top: 20px;
  font-size: 2em;
  padding: 15px;
  background: linear-gradient(to right, #f4f4f4, #e0e0e0);
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: fit-content;
  font-family: 'Georgia', serif;
}

.book-list-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* width: 100%; */
}

.book-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 800px;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #888;
}

.MuiButtonBase-root.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* メディアクエリ */
@media (max-width: 768px) {
  .book-list-container {
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
    padding: 10px;
    line-height: 1.2;
    background: linear-gradient(to right, #f4f4f4, #e0e0e0);
  }
}

@media (max-width: 590px) {
  h1 {
    font-size: 1.5em;
    padding: 10px;
    line-height: 1.2;
    word-wrap: break-word;
    background: linear-gradient(to right, #f4f4f4, #e0e0e0);
  }

  h1 br {
    display: inline;
  }
}
