/* ShareButtons.css */
.share-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.share-button {
  width: 30%
}

.share-button.facebook {
  background-color: #3b5998;
  color: #fff;
  text-transform: none;
}

.share-button.x {
  background-color: #000;
  color: #fff;
  text-transform: none;
}

/* 小さい画面向けのスタイル */
@media (max-width: 768px) {
  .share-button {
    width: 50%;
  }

}

@media (max-width: 600px) {
  /* .share-buttons {
    flex-direction: column;
    gap: 5px;
  } */
  .share-button {
    width: 50%;
  }
  .share-button.facebook {
    font-size: 0.8em;
  }
  .ahre-button.x {
    font-size: 0.8em;
  }
}
